<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="organization">
        <div class="man-title">
          组织架构
          <el-button @click="add(0, '公司')" class="btn" type="primary" size="mini" :disabled="roleId == 2 || roleId == 3 || roleId == 4">新增部门</el-button>
        </div>

        <div class="table-tree">
          <div class="tree">
            <div class="tree-title"><i class="el-icon-share"></i> 部门结构</div>
            <el-tree
              :data="treeData"
              @node-click="treeRowClick"
              :props="defaultProps"
              default-expand-all
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                <el-dropdown placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more-outline operate"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="add(data.id, data.name)">添加子部门</div></el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="edit(data)">修改</div></el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dlt(data)">删除</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </el-tree>
          </div>
          
          <div class="table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="name" label="姓名"></el-table-column>
              <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">
                  <span v-if="scope.row.sex == 1">男</span>
                  <span v-else>女</span>
                </template>
              </el-table-column>
              <el-table-column prop="departmentName" label="部门"></el-table-column>
              <el-table-column prop="name" label="岗位">
                <template slot-scope="scope">
                  <div
                    v-for="item in scope.row.positionInfoList"
                    :key="item.positionId"
                  >{{item.positionName}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="电话"></el-table-column>
            </el-table>
          </div>
        </div>

        <el-dialog
          title="修改名称"
          :visible.sync="dialogVisible"
          width="500px"
          >
          <el-form :model="nameForm" :rules="rules" ref="ruleForm1" label-width="100px">
            <el-form-item label="名称" prop="name">
              <el-input v-model="nameForm.name"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="添加"
          :visible.sync="dialogVisible2"
          width="500px"
          >
          <el-form :model="addForm" :rules="rules2" ref="ruleForm2" label-width="100px">
            <el-form-item label="上级部门">
              <el-input v-model="topName" disabled></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible2 = false">取 消</el-button>
            <el-button size="small" type="primary" @click="ok">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="编辑"
          :visible.sync="dialogVisible3"
          width="500px"
          key="789"
          >
          <el-form :model="editForm" ref="ruleForm3" label-width="100px">
            <el-form-item label="名称" prop="name">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible3 = false">取 消</el-button>
            <el-button size="small" type="primary" @click="ok2">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      nameForm: {},
      addForm: {},
      editForm: {
        name: ''
      },
      treeData: [],
      tableData: [],
      parentId: 0,
      topName: '',
      dialogTitle: '',
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      rules: {
        name: [
          { required: true, message: '必填项', trigger: 'blur' }
        ]
      },
      rules2: {
        name: [
          { required: true, message: '必填项', trigger: 'blur' }
        ],
        top: [
          { required: true, message: '必填项', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '必填项', trigger: 'blur' }
        ]
      },
      roleId: 0
    }
  },
  methods: {
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('departmentDelete', {
          id: row.id
        }).then(res => {
          this.loadList()
        })
      })
    },
    editCompanyName(row) {
        // this.dialogTitle = '编辑'
        // this.dialogVisible = true
        // this.form = Object.assign({}, row) 
    },
    edit(row) {
      this.$router.push({ name: 'organizationEdit', params: row })
      // this.parentId = id
      // this.editForm.name = name
      // this.dialogVisible3 = true
    },
    add(id, name) {
      if(id==0&&this.treeData.length>0) {
        return this.$message.warning('一级部门已存在,如需创建下属部门,通过部门右侧[ · · · ]进行创建。')
      }
      this.$router.push({ name: 'organizationAdd', params: {
        id: id,
        name: name
      }})
    },
    ok() {
      this.$ajax.post('departmentCreate', {
        name: this.addForm.name,
        parentId: this.parentId
      }).then(res => {
        this.loadList()
        this.dialogVisible2 = false
      })
    },
    ok2() {
      this.$ajax.post('departmentUpdate', {
        name: this.editForm.name,
        id: this.parentId
      }).then(res => {
        this.loadList()
        this.dialogVisible3 = false
      })
    },
    loadTableData(id) {
      this.loading = true
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    loadList() {
      this.$ajax.get('departmentTree').then(res => {
        this.treeData = res.data
      })
    },
  },
  mounted() {
    this.loadList()
    this.loadTableData()
    this.roleId = sessionStorage.getItem('r')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.organization {
  height: 100%;
}
.btn {
  float: right;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
  
}
.company-name {
  font-size: 18px;
  padding: 10px 0;
  margin: 0 10px;
  line-height: 28px;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
  
}
</style>